import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div id="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <small>
                            <i className="fa fa-copyright text-secondary" aria-hidden="true"> 2024 Built & Designed by Traeger Winn</i>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;