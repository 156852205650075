import React from 'react';
import './programming.css';

const Programming = () => {
    return (
        <div id="programming">
            <div className="container d-flex align-items-center justify-content-start fade-in-fwd programming-container pt-5">
                <div className="row d-flex align-items-center justify-content-start pt-5 pb-5 px-5">
                    <div className="col-12 pt-5 px-5">
                        {/* <h4 className="text-white display-5 pt-2 pb-2">
                            Traeger originally began programming in 2015 through classes offered at his high school, where he learned C# and Java.
                        </h4>
                        <h4 className="text-secondary display-5 pt-2 pb-2">
                            After a few years spent learning the fundementals of programming with those languages, and high school coming to its end - he decided to take his coding skills to the next level.
                        </h4>
                        <h4 className="text-white display-5 pt-2 pb-2">
                            In 2018 he took the core programming concepts he had learned over the years and enrolled in a course specifically tailored toward web development. Its here he learned all about the web development process and became proficient in and began specializing in Javascript.  
                        </h4>
                        <h4 className="text-white display-5 pt-2 pb-2">
                            By the end of 2018, he had landed his first job as a professional software developer. In his free time, he would do free lance work on the side primarily arising from word of mouth. 
                        </h4> */}
                        <h2 className="text-white display-5 pt-2 pb-2 programming-text">
                            He designed and built software such as — a custom javascript graphing system for different personality types, custom css animated spinner for random/mystery item opening, an integrated bot for the slack communication app, as well as websites ranging from simple client based code to full stack web apps with very complex databases. He also wrote unit and end-to-end tests and even spent time remotely managing windows servers.
                        </h2>
                        {/* <h2 className="text-white display-5 pt-2 pb-2 about-text">
                            He got his hands on a wide range of languages, libraries, and technologies such as HTML, CSS, Javascript, Python, Less/Sass, React, Redux, Meteor, Bootstrap, Mocha, Chai, Nightwatch, Node, Express, Mongodb, etc. 
                        </h2> */}
                        {/* <h4 className="text-secondary display-5 pt-2 pb-2">
                        <span className="text-white display-5 pt-2 pb-2">
                            Needless to say, he was very busy. Beside programming, he also spent time through-out the years investing. 
                        </span>
                            At first by flipping cars by the age of 16, and then gradually moving into the stock market a few years later. He always dreamed of building a program that had practical and functional use to him and the world. With his experience as an investor, and a programmer, he finally had his idea - but didnt have the time.
                        </h4>
                        <h4 className="text-white display-5 pt-2 pb-2">
                            So in 2020 he decided to leave his job as a software developer so that he could focus on developing his own stock market based web app, which integrates multiple different API’s, manages complex amounts of data, and handles many mathematical computations. As well as making time for his own self discovery/developmental journey.
                        </h4> */}
                        {/* <h4 className="text-white display-5 pt-2 pb-2">
                            Fast forward to present 2022 with his personal stock based web app where he wants it to be, and an adequate amount of time spent reflecting - he’s eager to get into some new projects that will challenge his mind. 
                        </h4>
                        <h4 className="text-white display-5 pt-2 pb-2">
                            He specializes in Javascript and the MERN stack, where he excels and enjoys working. But would love to learn new technologies/languages so that he can continue to deepen his knowledge in programming.
                        </h4>
                        <h4 className="text-white display-5 pt-2 pb-2">
                            He has an exceptional ability to problem solve, and think outside of the box. Not only in programming, but in all aspects of life. He is a quick learner, and has a vast range of knowledge. He has never been afraid to solve difficult or tedious problems whether it was replacing the clutch in his car, rebuilding the drivetrain on his mountain bike, or repairing his cracked iPhone screen.
                        </h4> */}
                        {/* <h4 className="text-white display-5 pt-2 pb-2">
                            Think you have a challenging project that would interest him or boggle his mind? He’d love to hear what you're working on - whether its a bug to fix, a simple website to be built, or a complex monster of a site to be tamed. Contact him today and share what youre working on!
                        </h4> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Programming;