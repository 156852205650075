import React from 'react';
import './home.css'

const Home = () => {
    return (
        <div id="home">

            <div className="container d-flex align-items-center justify-content-start fade-in-fwd intro">
                <div className="row d-flex align-items-center justify-content-start p-3">
                    <div className="col-12">
                        <h1 className="text-white display-1 phil-font title">
                            Traeger Winn
                        </h1>
                    </div>
                    <div className="col-12">
                        <h1 className="text-secondary display-3 phil-ital-font secondary-title">
                            Development
                        </h1>
                    </div>
                </div>  
            </div>

        </div>
    );
};
export default Home;