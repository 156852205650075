import React from 'react';
import './contact.css';

const Contact = () => {
    return (
        <div id="contact" className="d-flex align-items-center justify-content-start">
            <div className="container fade-in-fwd">
                <div className="row d-flex align-items-center justify-content-center pt-5 pb-5">
                    {/* <div className="col-12 d-flex align-items-center justify-content-center">
                        <h1 className="text-secondary display-4 contact-text">
                            <a href="mailto:traeger.winn@gmail.com" className="text-white text-break">traeger.winn@gmail.com</a>
                        </h1>
                    </div> */}
                    <div className="col-12 pt-5 pb-5 d-flex align-items-center justify-content-center">
                        <a name="blog" href="https://medium.com/@traeger.winn">
                            <i className="fa fa-medium fa-4x text-secondary my-icon" aria-hidden="true"></i>
                        </a>
                        <a name="instagram" href="https://www.instagram.com/traegerwinn">
                            <i className="fa fa-instagram fa-4x text-secondary ml-4 my-icon" aria-hidden="true"></i>
                        </a>
                        {/* <a name="linkedin" href="https://www.linkedin.com/in/traegerwinn/">
                            <i className="fa fa-linkedin fa-4x text-secondary my-icon" aria-hidden="true"></i>
                        </a> */}
                        {/* <a name="github" href="https://github.com/TrickaTrae">
                            <i className="fa fa-github fa-4x text-secondary ml-4 my-icon" aria-hidden="true"></i>
                        </a> */}
                    </div>
                    <div className="col-12 pt-3 pb-3 d-flex align-items-center justify-content-center">
                        <a href="https://www.buymeacoffee.com/TraegerWinn">
                            <img className="my-coffee-button" src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=TraegerWinn&button_colour=FFDD00&font_colour=000000&font_family=Bree&outline_colour=000000&coffee_colour=ffffff" alt="Buy me a coffee button"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Contact;