import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NavBar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import Home from '../pages/home/home';
import Programming from '../pages/programming/programming';
import Contact from '../pages/contact/contact';
// import Blog from '../pages/blog/blog';
import User from '../pages/user/user';
import Admin from '../pages/admin/admin';
import Music from '../pages/music/music';
import ScrollToTop from './scroll-to-top';
import '../-global-styles/animations.css'
import '../-global-styles/styles.css';

export default () => (
    <BrowserRouter>
    <ScrollToTop />
    <NavBar/>
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/programming' component={Programming}/>
            <Route exact path='/music' component={Music}/>
            <Route path='/contact' component={Contact}/>
            {/* <Route path='/blog' component={Blog}/> */}
            <Route path='/user' component={User}/>
            <Route path='/admin' component={Admin}/>
        </Switch>
    <Footer/>
    </BrowserRouter>
)